@use '@angular/material' as mat;
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

/* paletts generated using http://mcg.mbitson.com */

// CE Green #007F6E
$md-careevolutiongreen: (50 : #e0f0ee,
  100 : #b3d9d4,
  200 : #80bfb7,
  300 : #4da59a,
  400 : #269284,
  500 : #007f6e,
  600 : #007766,
  700 : #006c5b,
  800 : #006251,
  900 : #004f3f,
  A100 : #82ffdf,
  A200 : #4fffd2,
  A400 : #1cffc5,
  A700 : #03ffbf,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ));

// dark blue #002E6D
$md-darkblue: (50 : #e0e6ed,
  100 : #b3c0d3,
  200 : #8097b6,
  300 : #4d6d99,
  400 : #264d83,
  500 : #002e6d,
  600 : #002965,
  700 : #00235a,
  800 : #001d50,
  900 : #00123e,
  A100 : #748cff,
  A200 : #4162ff,
  A400 : #0e38ff,
  A700 : #002af3,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  ));

// warning red #C40D3C
$md-warningred: (50 : #f8e2e8,
  100 : #edb6c5,
  200 : #e2869e,
  300 : #d65677,
  400 : #cd3159,
  500 : #c40d3c,
  600 : #be0b36,
  700 : #b6092e,
  800 : #af0727,
  900 : #a2031a,
  A100 : #ffccd1,
  A200 : #ff99a3,
  A400 : #ff6674,
  A700 : #ff4d5d,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ));

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$rosetta-app-primary: mat.define-palette($md-careevolutiongreen);
$rosetta-app-accent: mat.define-palette($md-darkblue);

// The warn palette is optional (defaults to red).
$rosetta-app-warn: mat.define-palette($md-warningred);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$rosetta-app-theme: mat.define-light-theme((color: (primary: $rosetta-app-primary,
      accent: $rosetta-app-accent,
      warn: $rosetta-app-warn,
    )));

// useful individual colors
$rosetta-app-light-gray: #A2A9AD;
$rosetta-app-light-aqua: #A9DCD6;
$rosetta-app-accent-purple:#853275;
$rosetta-app-highlight-bg: $rosetta-app-accent-purple;
$rosetta-app-highlight-fg: #ffffffde;
$rosetta-app-highlight-padding: 4px;
$rosetta-app-highlight-border-radius: 4px;

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($rosetta-app-theme);
