/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Jost", "Helvetica Neue", sans-serif;
  font-weight: 400;
}

.content {
  padding: 0 16px;
}
